/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import {RiArrowLeftSLine, RiSkullLine} from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout sx={errorStyles.notFoundPage}>
    <SEO title="Page not found"/>
    <div sx={errorStyles.wrapper}>
      <header>
        <RiSkullLine sx={errorStyles.icon}/>
        <h1 sx={{mb: 0, color:"text"}}>Oops we did not expect that to happen</h1>
        <p sx={{mt: 2, color: "textColor"}}>Have you wondered into the unknow. Let us help you, Please take a look at below options</p>
      </header>
      <Link to="/" sx={errorStyles.btn}>
        <RiArrowLeftSLine sx={errorStyles.backIcon}/><span>Back to Homepage</span>
      </Link>
    </div>
  </Layout>
)

export default NotFound

const errorStyles = {
  notFoundPage: {
    pt:"130px",
    minHeight:"100vh"
	},
	wrapper: {
		maxWidth: "650px",
    m: "0 auto",
    textAlign: "center"
  },
  icon: {
    fontSize: "128px",
    color: "brand"
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    bg: "headerBg",
    color:"#fff",
    p: 3,
    mt: 4,
    borderRadius: "16px",
    "&:hover":{
      color:"#517701"
    }
  },
  backIcon:{
    fontSize: "24px",
    }
}